import { useMainStore } from "@/store";

/**
 * 指定需要登录后返回当前页
 */
export function loginAndBack(backPath?: string) {
  const route = useRoute();
  const router = useRouter();
  const loginTo = backPath || route.fullPath;
  router.replace({ path: '/', query: { loginTo } });
}

export function watchLoginAndBack(pathRef: Ref<string>) {
  const loginVisible = useState('loginVisible');
  const mainStore = useMainStore();
  const router = useRouter();
  watch([pathRef, () => mainStore.currentUser, () => mainStore.currentUserSolved], () => {
    console.debug('watchLoginAndBack pathRef', pathRef.value, mainStore.currentUser, mainStore.currentUserSolved);
    if (!mainStore.currentUserSolved) {
      return;
    }
    const route = useRoute();
    const loginTo = route.path === '/' && route.query.loginTo as string;
    if (!loginTo) {
      return;
    }
    if (mainStore.currentUser) {
      router.push(loginTo);
    } else {
      loginVisible.value = true;
    }
  }, { immediate: true });
}